const regionColumn = [
    {
        title: "No",
        dataIndex: "",
        key: 'no',
        width: '5%',
        render: (text, record, index) => index + 1,
    },
    {
        title: "ID",
        dataIndex: "id",
        key: 'id',
        width: '5%',
    },
    {
        title: "Nama Wilayah",
        dataIndex: "region_name",
        key: 'region_name',
        width: '20%',
        sorter: (a, b) => a.region_name.localeCompare(b.region_name),
    },
    {
        title: "Alamat",
        dataIndex: "region_address",
        key: 'region_address',
        width: '50%',
        sorter: (a, b) => a.region_address.localeCompare(b.region_address),
        sortDirections: ['descend', 'ascend']
    }
]

export default regionColumn