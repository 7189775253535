import moment from "moment";

const TransactionColumn = [
    {
        title: "No",
        dataIndex: "",
        key: 'no',
        width: '4%',
        render: (text, record, index) => index + 1,
    },
    {
        title: "ID",
        dataIndex: "id",
        key: 'id',
        width: '4%',
        sorter: (a, b) => a['id'] - b['id'],
    },
    {
        title: "ID Barang",
        dataIndex: "goods_id",
        key: 'goods_id',
        width: '8%',
        sorter: (a, b) => a['goods_id'] - b['goods_id'],
    },
    {
        title: "Nama Barang",
        dataIndex: "goods_name",
        key: 'goods_name',
        width: '16%',
        sorter: (a, b) => a.goods_name.localeCompare(b.goods_name),
    },
    {
        title: "Tipe",
        dataIndex: "type",
        key: 'type',
        width: '8%',
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: (text) => (
            text.includes('INCOME') ?
                <div style={{color: "#73d13d"}}>{text}</div> :
                <div style={{color: "#ff7875"}}>{text}</div>
        )
    },
    {
        title: "Jumlah",
        dataIndex: "goods_qty",
        key: 'goods_qty',
        width: '4%',
        sorter: (a, b) => a['goods_qty'] - b['goods_qty'],
        render: (text, record) => (
            record.type.includes('INCOME') ?
                <div style={{color: "#73d13d"}}>{text}</div> :
                <div style={{color: "#ff7875"}}>{text}</div>
        )
    },
    {
        title: "Harga",
        dataIndex: "price",
        key: 'price',
        width: '12%',
        sorter: (a, b) => a['price'] - b['price'],
        render: (text, record) => (
            record.type.includes('INCOME') ?
                <div style={{color: "#73d13d"}}>{`Rp. ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div> :
                <div style={{color: "#ff7875"}}>{`Rp. ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
        )
    },
    {
        title: "Total Harga",
        dataIndex: "total_price",
        key: 'total_price',
        width: '12%',
        sorter: (a, b) => a['total_price'] - b['total_price'],
        render: (text, record) => (
            record.type.includes('INCOME') ?
                <div style={{color: "#73d13d"}}>{`Rp. ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div> :
                <div style={{color: "#ff7875"}}>{`Rp. ${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
        )
    },
    {
        title: "Input oleh",
        dataIndex: "created_by",
        key: 'created_by',
        width: '12%',
        sorter: (a, b) => a.created_by.localeCompare(b.created_by),
    },
    {
        title: "Input pada",
        dataIndex: "created_at",
        key: 'created_at',
        width: '20%',
        sorter: (a, b) => new Date(b['created_at']) - new Date(a['created_at']),
        sortDirections: ['descend', 'ascend'],
        render: (text => moment(text).format('DD-MM-YYYY hh:mm:ss'))
    },

]

export default TransactionColumn