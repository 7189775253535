import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {getRegionAll, postRegionCreate, postRegionDelete, postRegionUpdate} from "../../repository/region";
import {
    Button,
    Col,
    Divider,
    Input,
    Layout,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Typography
} from "antd";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import regionColumn from "./RegionColumn";
import {Notification} from "../../components/notification/Notification";
import moment from "moment";

const {Title} = Typography;
const {Content} = Layout;

function RegionPage() {
    const auth = useSelector((state) => state.user)
    const user = auth == null ? {name: 'N'} : auth
    const searchInput = useRef(null);
    const [dataTable, setDataTable] = useState()
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState({})
    const [onEdit, setOnEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)

    const doGetRegionList = async () => {
        setLoading(true)
        const {status, region} = await getRegionAll()
        if (status) {
            setLoading(false)
            setDataTable(region)
        }
    }

    const cmdRegionCreate = async () => {
        setLoading(true)
        const {status, region, message} = await postRegionCreate(selectedRegion)
        if (status) {
            Notification('success', 'Berhasil diupdate !')
            doGetRegionList()
            setLoading(false)
            showModalAdd()
        } else {
            Notification('error', message)
            setLoading(false)
            showModalAdd()
        }
    }

    const cmdDeleteRegion = async () => {
        setLoading(true)
        const {status, region, message} = await postRegionDelete(selectedRegion.id)
        if (status) {
            Notification('success', 'Berhasil dihapus !')
            doGetRegionList()
            setLoading(false)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    const cmdRegionUpdate = async () => {
        setLoading(true)
        const {status, region, message} = await postRegionUpdate(selectedRegion)
        if (status) {
            Notification('success', 'Berhasil diupdate !')
            doGetRegionList()
            setLoading(false)
            showModalDetail()
        } else {
            Notification('error', message)
            setLoading(false)
            showModalDetail()
        }
    }

    useEffect(() => {
        doGetRegionList()
    }, []);

    const showModalAdd = () => {
        setModalAdd(!modalAdd)
        setSelectedRegion({
            created_by: user.name,
            updated_by: user.name
        })
    }

    const showModalDetail = () => {
        setModalDetail(!modalDetail)
        setOnEdit(false)
    }

    const onChangeRegion = (event, value, key) => {
        if (event == undefined) {
            setSelectedRegion({
                ...selectedRegion,
                [key]: value
            })
        } else {
            setSelectedRegion({
                ...selectedRegion,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const regionCol = regionColumn.map(obj => {
        if (obj.key === 'region_name') {
            return {...obj, ...getColumnSearchProps('region_name')}
        } else if (obj.key === 'region_address') {
            return {...obj, ...getColumnSearchProps('region_address')}
        }
        return obj
    })

    const column = [...regionCol, {
        title: 'Aksi',
        key: 'aksi',
        width: '20%',
        render: (text, record, index) => (
            <Space>
                <Button
                    onClick={() => {
                        setSelectedRegion(record)
                        showModalDetail()
                    }}
                >
                    Detail
                </Button>
                <Popconfirm
                    title="Hapus data ini?"
                    onConfirm={cmdDeleteRegion}
                    okText="Ya"
                    cancelText="Tidak"
                >
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            setSelectedRegion(record)
                        }}
                    >
                        Hapus
                    </Button>
                </Popconfirm>
            </Space>
        )
    }]

    return (
        <>
            {/*Main Layout*/}
            <Layout className="site-layout">
                <Content
                    style={{margin: '0 16px'}}>
                    <div
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                            backgroundColor: '#FFFFFF',
                            borderRadius: '25px'
                        }}
                    >
                        <Space direction={'vertical'} style={{display: 'flex'}} size={'small'}>
                            <Row align={'middle'}>
                                <Col flex={30}>
                                    <Title level={2}> Data Wilayah </Title>
                                </Col>
                                <Col flex={'auto'}>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        icon={<PlusOutlined/>}
                                        size={'large'}
                                        onClick={showModalAdd}
                                    >
                                        Tambah Region
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Divider/>
                                </Col>
                            </Row>
                            <Table columns={column} dataSource={dataTable} scroll={{x: true}} loading={loading}/>
                        </Space>
                    </div>
                </Content>
            </Layout>

            {/*Detail*/}
            <Modal
                visible={modalDetail}
                title={`Detail - ${selectedRegion.region_name}`}
                onCancel={showModalDetail}
                width={800}
                footer={[
                    <Button key="back" onClick={showModalDetail}>
                        Kembali
                    </Button>,
                    <>{onEdit && <Button key="submit" type="primary" loading={loading} onClick={cmdRegionUpdate}>
                        Simpan
                    </Button>}</>
                ]}
            >
                {/*turn on edit*/}
                {user.role === 'SUPERADMIN' &&
                <Row align={'end'}>
                    <Col span={2.5}>
                        <Switch
                            checked={onEdit}
                            checkedChildren="Edit Aktif"
                            unCheckedChildren="Edit Nonaktif"
                            onChange={() => {
                                setOnEdit(!onEdit)
                            }}
                        />
                    </Col>
                </Row>}
                {/*Form*/}
                <Row gutter={[8, 32]}>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>ID Wilayah</h4>
                                    <Input
                                        name={'id'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedRegion.id}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Nama Wilayah</h4>
                                    <Input
                                        name={'region_name'}
                                        readOnly={!onEdit}
                                        value={selectedRegion.region_name}
                                        onChange={event => onChangeRegion(event)}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Alamat</h4>
                                    <Input
                                        name={'region_address'}
                                        readOnly={!onEdit}
                                        value={selectedRegion.region_address}
                                        onChange={event => onChangeRegion(event)}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Dibuat Tanggal</h4>
                                    <Input
                                        name={'created_at'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={moment(selectedRegion.created_at).format('DD-MM-YYYY hh:mm:ss')}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Dibuat oleh</h4>
                                    <Input
                                        name={'created_by'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedRegion.created_by}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Terakhir Diubah</h4>
                                    <Input
                                        name={'updated_at'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={moment(selectedRegion.updated_at).format('DD-MM-YYYY hh:mm:ss')}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Ubah Oleh</h4>
                                    <Input
                                        name={'updated_by'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedRegion.updated_by}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>

            {/*Add*/}
            <Modal
                visible={modalAdd}
                title={'Tambah Region'}
                onCancel={showModalAdd}
                width={800}
                footer={[
                    <Button key="back" onClick={showModalAdd}>
                        Kembali
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={cmdRegionCreate}>
                        Simpan
                    </Button>
                ]}
            >
                {/*Form*/}
                <Row gutter={[8, 32]}>
                    <Col span={24}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Nama Wilayah</h4>
                                    <Input
                                        name={'region_name'}
                                        value={selectedRegion.region_name}
                                        onChange={event => onChangeRegion(event)}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Alamat Wilayah</h4>
                                    <Input
                                        name={'region_address'}
                                        value={selectedRegion.region_address}
                                        onChange={event => onChangeRegion(event)}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default RegionPage