import axiosIntance from "./axios-instance";
import Constant from '../constant'

const getRegionAll = async () => {
    try {
        let response = await axiosIntance().get("region/all", {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, region: response.data.region}
    } catch (error) {
        return {
            status: false,
            region: null,
            message: error.response.message
        }
    }
}

const getRegionGet = async () => {
    try {
        let response = await axiosIntance().get("region/get", {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, region: response.data.region}
    } catch (error) {
        return {
            status: false,
            region: null,
            message: error.response.message
        }
    }
}

const postRegionCreate = async (request) => {
    try {
        let response = await axiosIntance().post("region/insert", request, {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, region: response.data.region}
    } catch (error) {
        return {
            status: false,
            region: null,
            message: error.response.message
        }
    }
}

const postRegionUpdate = async (request) => {
    try {
        let response = await axiosIntance().post("region/update", request, {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, region: response.data.region}
    } catch (error) {
        return {
            status: false,
            region: null,
            message: error.response.message
        }
    }
}

const postRegionDelete = async (id) => {
    try {
        let response = await axiosIntance().post("region/delete/" + id, {}, {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, region: response.data.region}
    } catch (error) {
        return {
            status: false,
            region: null,
            message: error.response.message
        }
    }
}




export {
    getRegionAll,
    getRegionGet,
    postRegionUpdate,
    postRegionDelete,
    postRegionCreate
}