import {
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    Layout,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Typography
} from "antd";
import goodsColumn from './GoodsColumn'
import {useEffect, useRef, useState} from "react";
import {FileExcelOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useSelector} from "react-redux";
import {
    getGoodsAll,
    getGoodsExport,
    postGoodsDelete,
    postGoodsInsert,
    postGoodsUpdateAll,
    postGoodsUpdateGoodsIn,
    postGoodsUpdateGoodsOut
} from '../../repository/goods'
import {Notification} from "../../components/notification/Notification";
import moment from "moment";
import {getRegionGet} from "../../repository/region";

const {Title} = Typography;
const {Content} = Layout;
const {Option} = Select;

function GoodsPage() {
    const auth = useSelector((state) => state.user)
    const user = auth == null ? {name: 'N'} : auth
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [dataTable, setDataTable] = useState()
    const [loading, setLoading] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalGoodsIn, setModalGoodsIn] = useState(false);
    const [modalGoodsOut, setModalGoodsOut] = useState(false);
    const [selectedGoods, setSelectedGoods] = useState({})
    const [onEdit, setOnEdit] = useState(false)
    const [region, setRegion] = useState([])
    const [selectedRegion, setSelectedRegion] = useState({})

    const doGetGoodsList = async () => {
        setLoading(true)
        const {status, goods} = await getGoodsAll(user)
        if (status) {
            setDataTable(goods)
            setLoading(false)
        }
    }

    const getRegionList = async () => {
        setLoading(true)
        const {status, region} = await getRegionGet()
        if (status) {
            const tempRegion = region.filter((f => f.id == user.region_id))[0]
            setRegion(region)
            setSelectedRegion(user.region_id == 1 ?
                {...region[0], region_id: region[0].id} :
                {...tempRegion, region_id: tempRegion.id})
            setLoading(false)
        }
    }

    const cmdGoodsList = async (query) => {
        setLoading(true)
        const {status, goods} = await getGoodsAll(query)
        if (status) {
            setDataTable(goods)
            setLoading(false)
        }
    }

    const cmdGoodsInsert = async () => {
        setLoading(true)
        const {status, goods, message} = await postGoodsInsert(selectedGoods)
        if (status) {
            cmdGoodsList(selectedRegion)
            Notification('success', 'Berhasil ditambahkan !')
        } else {
            Notification('error', message)
        }
        setLoading(false)
        showModalAdd()
    }

    const cmdGoodsUpdateAll = async () => {
        setLoading(true)
        const {status, goods, message} = await postGoodsUpdateAll({...selectedGoods, updated_by: user.name})
        if (status) {
            Notification('success', 'Berhasil diupdate !')
            cmdGoodsList(selectedRegion)
            setLoading(false)
            showModalDetail()
        } else {
            Notification('error', message)
            setLoading(false)
            showModalDetail()
        }
    }

    const cmdDeleteGoods = async () => {
        setLoading(true)
        const {status, message} = await postGoodsDelete(selectedGoods.id)
        if (status) {
            setTimeout(() => {
                Notification('success', 'Berhasil dihapus !')
                cmdGoodsList(selectedRegion)
                setLoading(false)
            }, 1000)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    const cmdGoodsIn = async () => {
        setLoading(true)
        const {status, message} = await postGoodsUpdateGoodsIn({...selectedGoods, updated_by: user.name})
        if (status) {
            setTimeout(() => {
                Notification('success', 'Berhasil diupdate !')
                cmdGoodsList(selectedRegion)
                setLoading(false)
                showModalGoodsIn()
            }, 1000)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    const cmdGoodsOut = async () => {
        setLoading(true)
        const {status, message} = await postGoodsUpdateGoodsOut({...selectedGoods, updated_by: user.name})
        if (status) {
            setTimeout(() => {
                Notification('success', 'Berhasil diupdate !')
                cmdGoodsList(selectedRegion)
                setLoading(false)
                showModalGoodsOut()
            }, 1000)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    const cmdExportToExcel = async () => {
        setLoading(true)
        const {status, message} = await getGoodsExport(selectedRegion)
        if (status) {
            setTimeout(() => {
                Notification('success', 'Berhasil didownload !')
                setLoading(false)
            }, 1000)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    useEffect(() => {
        getRegionList()
        doGetGoodsList();
    }, []);

    const showModalDetail = () => {
        setModalDetail(!modalDetail);
        setOnEdit(false)
    };

    const showModalAdd = () => {
        setModalAdd(!modalAdd);
        setSelectedGoods({
            goods_in: 0,
            goods_out: 0,
            total_goods_in: 0,
            total_goods_out: 0,
            region_id: selectedRegion.id != null ? selectedRegion.id : selectedRegion.region_id,
            region_name: selectedRegion.region_name,
            created_by: user.name,
            updated_by: user.name,
        })
    };

    const showModalGoodsIn = () => {
        setModalGoodsIn(!modalGoodsIn);
    };

    const showModalGoodsOut = () => {
        setModalGoodsOut(!modalGoodsOut);
    };

    const onChangeGoods = (event, value, key) => {
        if (event == undefined) {
            let validValue = Number(value)
            if (validValue) {
                if (key == 'goods_in' && !onEdit) {
                    setSelectedGoods({
                        ...selectedGoods,
                        [key]: validValue,
                        total_goods_in: validValue,
                        stock: validValue - selectedGoods.total_goods_out
                    })
                } else if (key == 'goods_out' && !onEdit) {
                    setSelectedGoods({
                        ...selectedGoods,
                        [key]: validValue,
                        total_goods_out: validValue,
                        stock: selectedGoods.total_goods_in - validValue
                    })
                } else {
                    setSelectedGoods({
                        ...selectedGoods,
                        [key]: validValue,
                        stock: selectedGoods.total_goods_in - selectedGoods.total_goods_out
                    })
                }
            } else if (key === 'region_id') {
                setSelectedGoods({
                    ...selectedGoods,
                    [key]: value,
                    region_name: region.filter((f => f.id == value))[0]['region_name']
                })
            }
        } else {
            setSelectedGoods({
                ...selectedGoods,
                [event.target.name]: event.target.value
            })
        }
    }

    const onChangeGoodsIn = (value, key) => {
        setSelectedGoods({
            ...selectedGoods,
            [key]: value
        })
    }

    const onChangeGoodsOut = (value, key) => {
        setSelectedGoods({
            ...selectedGoods,
            [key]: value
        })
    }

    const handleRegion = (value) => {
        let tempRegion = region.filter((f => f.id == value))[0]
        let data = {
            region_id: value,
            region_name: tempRegion.region_name,
            region_address: tempRegion.region_address
        }
        setSelectedRegion(data)
        cmdGoodsList(data)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const goodsCol = goodsColumn.map(obj => {
        if (obj.key === 'goods_name') {
            return {...obj, ...getColumnSearchProps('goods_name')}
        }
        return obj
    })

    const column = [...goodsCol, {
        title: "Aksi",
        key: "aksi",
        width: '20%',
        render: (text, record, index) => (
            <Space>
                {(user.role === 'SUPERADMIN' || user.role === 'ADMIN') &&
                <Button
                    onClick={() => {
                        setSelectedGoods(record)
                        showModalGoodsIn()
                    }}
                >
                    Barang Masuk
                </Button>}
                {(user.role === 'SUPERADMIN' || user.role === 'ADMIN' || user.role === 'AUDIT') &&
                <Button
                    onClick={() => {
                        setSelectedGoods(record)
                        showModalGoodsOut()
                    }}
                >
                    Barang Keluar
                </Button>
                }
                <Button
                    onClick={() => {
                        setSelectedGoods(record)
                        showModalDetail()
                    }}
                >
                    Detail
                </Button>
                {(user.role === 'SUPERADMIN' || user.role === 'ADMIN') &&
                <Popconfirm
                    title="Hapus data ini?"
                    onConfirm={cmdDeleteGoods}
                    okText="Ya"
                    cancelText="Tidak"
                >
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            setSelectedGoods(record)
                        }}
                    >
                        Hapus
                    </Button>
                </Popconfirm>}
            </Space>
        ),
    }]

    return (
        <>
            {/*Main Layout*/}
            <Layout className="site-layout">
                <Content
                    style={{margin: '0 16px'}}>
                    <div
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                            backgroundColor: '#FFFFFF',
                            borderRadius: '25px'
                        }}
                    >
                        <Space direction={'vertical'} style={{display: 'flex'}} size={'small'}>
                            <Row align="middle">
                                <Col flex={30}>
                                    <Title level={2}> Data Barang </Title>
                                </Col>
                                <Col flex={'auto'}>
                                    <Space>
                                        <Button
                                            style={{
                                                color: '#73d13d',
                                                borderColor: '#73d13d'
                                            }}
                                            shape="round"
                                            icon={<FileExcelOutlined/>}
                                            size={'large'}
                                            onClick={cmdExportToExcel}
                                        >
                                            Export ke Excel
                                        </Button>
                                        {(user.role === 'SUPERADMIN' || user.role === 'ADMIN') &&
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon={<PlusOutlined/>}
                                            size={'large'}
                                            onClick={showModalAdd}
                                        >
                                            Tambah Barang
                                        </Button>}
                                    </Space>
                                </Col>
                                <Col span={24}>
                                    <Divider/>
                                </Col>
                                {(user.role === 'SUPERADMIN') &&
                                <Col span={12}>
                                    <Space size={"large"}>
                                        <h4>Wilayah : </h4>
                                        <Select
                                            style={{width: '200px'}}
                                            loading={loading}
                                            name={'region_name'}
                                            value={selectedRegion.region_name}
                                            onChange={(value) => handleRegion(value)}
                                        >
                                            {region.map((element) => (
                                                <Option key={element.id}
                                                        value={element.id}>{element.region_name}</Option>
                                            ))}
                                        </Select>
                                    </Space>
                                </Col>}
                            </Row>

                            <Table columns={column} dataSource={dataTable} scroll={{x: true}} loading={loading}/>
                        </Space>
                    </div>
                </Content>
            </Layout>

            {/*Goods In*/}
            <Modal
                visible={modalGoodsIn}
                title={`Barang Masuk - ${selectedGoods.goods_name}`}
                onCancel={showModalGoodsIn}
                width={500}
                footer={[
                    <Button key="back" onClick={showModalGoodsIn}>
                        Kembali
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={cmdGoodsIn}>
                        Simpan
                    </Button>
                ]}
            >
                <Space direction={'vertical'} style={{display: 'flex'}} size={'middle'}>
                    <Row>
                        <Col span={8}>
                            <h4>Jumlah Barang Masuk</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                keyboard={true}
                                value={selectedGoods.addGoodsIn}
                                onChange={(value) => onChangeGoodsIn(value, 'addGoodsIn')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <h4>Harga Beli</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                keyboard={true}
                                value={selectedGoods.addPriceIn}
                                prefix="Rp."
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onPressEnter={cmdGoodsIn}
                                onChange={(value) => onChangeGoodsIn(value, 'addPriceIn')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <h4>Total Harga Beli</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                readOnly={true}
                                keyboard={true}
                                value={selectedGoods.addGoodsIn * selectedGoods.addPriceIn}
                                prefix="Rp."
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                    </Row>
                </Space>
            </Modal>

            {/*Goods Out*/}
            <Modal
                visible={modalGoodsOut}
                title={`Barang Keluar - ${selectedGoods.goods_name}`}
                onCancel={showModalGoodsOut}
                width={500}
                footer={[
                    <Button key="back" onClick={showModalGoodsOut}>
                        Kembali
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={cmdGoodsOut}>
                        Simpan
                    </Button>
                ]}
            >
                <Space direction={'vertical'} style={{display: 'flex'}} size={'small'}>
                    <Row>
                        <Col span={8}>
                            <h4>Jumlah Barang Keluar</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                keyboard={true}
                                value={selectedGoods.addGoodsOut}
                                onPressEnter={cmdGoodsOut}
                                onChange={(value) => onChangeGoodsOut(value, 'addGoodsOut')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <h4>Harga Jual</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                keyboard={true}
                                readOnly={true}
                                value={selectedGoods.price}
                                prefix="Rp."
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <h4>Total Harga Jual</h4>
                        </Col>
                        <Col span={1}>
                            <h4>:</h4>
                        </Col>
                        <Col span={15}>
                            <InputNumber
                                style={{width: '100%'}}
                                min={0}
                                readOnly={true}
                                keyboard={true}
                                value={selectedGoods.price * selectedGoods.addGoodsOut}
                                prefix="Rp."
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                    </Row>
                </Space>
            </Modal>

            {/*Detail*/}
            <Modal
                visible={modalDetail}
                title={`Detail - ${selectedGoods.goods_name}`}
                onCancel={showModalDetail}
                width={800}
                footer={[
                    <Button key="back" onClick={showModalDetail}>
                        Kembali
                    </Button>,
                    <>{onEdit && <Button key="submit" type="primary" loading={loading} onClick={cmdGoodsUpdateAll}>
                        Simpan
                    </Button>}</>
                ]}
            >
                {/*turn on edit*/}
                {(user.role === 'SUPERADMIN' || user.role === 'ADMIN') &&
                <Row align={'end'}>
                    <Col span={2.5}>
                        <Switch
                            checked={onEdit}
                            checkedChildren="Edit Aktif"
                            unCheckedChildren="Edit Nonaktif"
                            onChange={() => {
                                setOnEdit(!onEdit)
                            }}
                        />
                    </Col>
                </Row>}
                {/*Form*/}
                <Row gutter={[8, 32]}>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>ID Barang</h4>
                                    <Input
                                        name={'id'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedGoods.id}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Nama Barang</h4>
                                    <Input
                                        name={'goods_name'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan nama barang"
                                        status={""}
                                        value={selectedGoods.goods_name}
                                        onChange={(event => onChangeGoods(event))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Stok</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'stock'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan jumlah stok barang"
                                        status={""}
                                        value={selectedGoods.stock}
                                        // onChange={(value => onChangeGoods(undefined, value, 'stock'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Harga</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'price'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan harga barang"
                                        status={""}
                                        prefix={"Rp"}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        value={selectedGoods.price}
                                        onChange={(value => onChangeGoods(undefined, value, 'price'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Barang Masuk (Hari ini)</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'goods_in'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan jumlah barang masuk hari ini"
                                        status={""}
                                        value={selectedGoods.goods_in}
                                        onChange={(value => onChangeGoods(undefined, value, 'goods_in'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Barang Keluar (Hari ini)</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'goods_out'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan jumlah barang keluar hari ini"
                                        status={""}
                                        value={selectedGoods.goods_out}
                                        onChange={(value => onChangeGoods(undefined, value, 'goods_out'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Wilayah</h4>
                                    <Select
                                        style={{width: '100%'}}
                                        disabled={onEdit && user.role != 'SUPERADMIN'}
                                        readOnly={!onEdit && user.role != 'SUPERADMIN'}
                                        name={'region_id'}
                                        open={onEdit ? undefined : onEdit}
                                        value={selectedGoods.region_name}
                                        onChange={value => onChangeGoods(undefined, value, 'region_id')}
                                    >
                                        {region.map((element) => (
                                            <Option key={element.id} value={element.id}>{element.region_name}</Option>
                                        ))}
                                    </Select>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Total Barang Masuk</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'total_goods_in'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan total barang masuk"
                                        status={""}
                                        value={selectedGoods.total_goods_in}
                                        onChange={(value => onChangeGoods(undefined, value, 'total_goods_in'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Total Barang Keluar</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'total_goods_out'}
                                        readOnly={!onEdit}
                                        placeholder="Masukkan total jumlah barang keluar"
                                        status={""}
                                        value={selectedGoods.total_goods_out}
                                        onChange={(value => onChangeGoods(undefined, value, 'total_goods_out'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Input oleh</h4>
                                    <Input
                                        name={'created_by'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedGoods.created_by}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Input Pada</h4>
                                    <Input
                                        name={'created_at'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={moment(selectedGoods.created_at).format('DD-MM-YYYY hh:mm:ss')}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Ubah Oleh</h4>
                                    <Input
                                        name={'updated_by'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={selectedGoods.updated_by}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Ubah Pada</h4>
                                    <Input
                                        name={'updated_at'}
                                        disabled={onEdit}
                                        readOnly={!onEdit}
                                        value={moment(selectedGoods.updated_at).format('DD-MM-YYYY hh:mm:ss')}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>

            {/*Tambah barang*/}
            <Modal
                visible={modalAdd}
                title={'Tambah Barang'}
                onCancel={showModalAdd}
                width={800}
                footer={[
                    <Button key="back" onClick={showModalAdd}>
                        Kembali
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={cmdGoodsInsert}>
                        Simpan
                    </Button>
                ]}
            >
                {/*Form*/}
                <Row gutter={[8, 32]}>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Nama Barang</h4>
                                    <Input
                                        name={'goods_name'}
                                        placeholder="Masukkan nama barang"
                                        status={""}
                                        value={selectedGoods.goods_name}
                                        onChange={(event => onChangeGoods(event))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Stok</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'stock'}
                                        placeholder="Masukkan jumlah stok barang"
                                        status={""}
                                        disabled={true}
                                        value={selectedGoods.stock}
                                        onChange={(value => onChangeGoods(undefined, value, 'stock'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Harga</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'price'}
                                        placeholder="Masukkan harga barang"
                                        status={""}
                                        prefix={"Rp"}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        value={selectedGoods.price}
                                        onChange={(value => onChangeGoods(undefined, value, 'price'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Barang Masuk (Hari ini)</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'goods_in'}
                                        placeholder="Masukkan jumlah barang masuk hari ini"
                                        status={""}
                                        value={selectedGoods.goods_in}
                                        onChange={(value => onChangeGoods(undefined, value, 'goods_in'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Barang Keluar (Hari ini)</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'goods_out'}
                                        placeholder="Masukkan jumlah barang keluar hari ini"
                                        status={""}
                                        value={selectedGoods.goods_out}
                                        onChange={(value => onChangeGoods(undefined, value, 'goods_out'))}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Total Barang Masuk</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'total_goods_in'}
                                        placeholder="Masukkan total barang masuk"
                                        status={""}
                                        value={selectedGoods.total_goods_in}
                                        onChange={(value => onChangeGoods(undefined, value, 'total_goods_in'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Total Barang Keluar</h4>
                                    <InputNumber
                                        style={{width: '100%'}}
                                        name={'total_goods_out'}
                                        placeholder="Masukkan total jumlah barang keluar"
                                        status={""}
                                        value={selectedGoods.total_goods_out}
                                        onPressEnter={cmdGoodsInsert}
                                        onChange={(value => onChangeGoods(undefined, value, 'total_goods_out'))}
                                    />
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Space direction={'vertical'} style={{display: 'flex'}}>
                                    <h4>Wilayah</h4>
                                    <Select
                                        style={{width: '100%'}}
                                        name={'region_id'}
                                        disabled={true}
                                        value={selectedGoods.region_name}
                                        onChange={value => onChangeGoods(undefined, value, 'region_id')}
                                    >
                                        {region.map((element) => (
                                            <Option key={element.id} value={element.id}>{element.region_name}</Option>
                                        ))}
                                    </Select>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </>
    )

}

export default GoodsPage;