import {useEffect, useRef, useState} from "react";
import {Button, Col, DatePicker, Divider, Input, Layout, Row, Select, Space, Table, Typography} from "antd";
import {FileExcelOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {Notification} from "../../components/notification/Notification";
import transactionColumn from './TransactionColumn'
import moment from "moment";
import {getTransactionAll, getTransactionExport} from "../../repository/transaction";
import {useSelector} from "react-redux";
import {getRegionGet} from "../../repository/region";

const {Title} = Typography;
const {Content} = Layout;
const {Option} = Select;

function TransactionPage() {
    const auth = useSelector((state) => state.user)
    const user = auth == null ? {name: 'N'} : auth
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [dataTable, setDataTable] = useState()
    const [loading, setLoading] = useState(false);
    const [exportDate, setExportDate] = useState(null)
    const [region, setRegion] = useState([])
    const [selectedRegion, setSelectedRegion] = useState({})

    const doGetTransactionList = async () => {
        setLoading(true)
        const {status, transaction} = await getTransactionAll(user)
        if (status) {
            setDataTable(transaction)
            setLoading(false)
        }
    }

    const getRegionList = async () => {
        setLoading(true)
        const {status, region} = await getRegionGet()
        if (status) {
            setRegion(region)
            setSelectedRegion(user.region_id == 1 ? region[0] : region.filter((f => f.id == user.region_id))[0])
            setLoading(false)
        }
    }

    const cmdTransactionList = async (value) => {
        setLoading(true)
        const {status, transaction} = await getTransactionAll({region_id : value})
        if (status) {
            setDataTable(transaction)
            setLoading(false)
        }
    }

    const cmdExportToExcel = async () => {
        setLoading(true)
        const {status, message} = await getTransactionExport({
            reportDate: `${moment(exportDate).format('DD-MM-YYYY')}`,
            region_id: selectedRegion.id != null ? selectedRegion.id : selectedRegion.region_id
        })
        if (status) {
            setTimeout(() => {
                Notification('success', 'Berhasil didownload !')
                setLoading(false)
            }, 1000)
        } else {
            Notification('error', message)
            setLoading(false)
        }
    }

    useEffect(() => {
        getRegionList()
        doGetTransactionList()
    }, [])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const column = transactionColumn.map(obj => {
        if (obj.key === 'goods_name') {
            return {...obj, ...getColumnSearchProps('goods_name')}
        } else if (obj.key === 'goods_id') {
            return {...obj, ...getColumnSearchProps('goods_id')}
        } else if (obj.key === 'id') {
            return {...obj, ...getColumnSearchProps('id')}
        }
        return obj
    })

    const handleExportDate = (value) => {
        setExportDate(value)
    }

    const handleRegion = (value) => {
        let tempRegion = region.filter((f => f.id == value))[0]
        let data = {
            region_id: value,
            region_name: tempRegion.region_name,
            region_address: tempRegion.region_address
        }
        setSelectedRegion(data)
        cmdTransactionList(value)
    }

    return (
        <>
            {/*Main Layout*/}
            <Layout className="site-layout">
                <Content
                    style={{margin: '0 16px'}}>
                    <div
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                            backgroundColor: '#FFFFFF',
                            borderRadius: '25px'
                        }}
                    >
                        <Space direction={'vertical'} style={{display: 'flex'}} size={'small'}>
                            <Row align="middle">
                                <Col flex={30}>
                                    <Title level={2}> Data Transaksi</Title>
                                </Col>
                                <Col flex={'auto'}>
                                    <Space size={'large'}>
                                        <DatePicker
                                            style={{width: '240px'}}
                                            placeholder={'Pilih tanggal untuk export'}
                                            format={['DD-MM-YYYY']}
                                            size={'large'}
                                            value={exportDate}
                                            onChange={(date) => handleExportDate(date)}
                                        />
                                        <Button
                                            style={{
                                                color: '#73d13d',
                                                borderColor: '#73d13d'
                                            }}
                                            disabled={exportDate == null ? true : false}
                                            shape="round"
                                            icon={<FileExcelOutlined/>}
                                            size={'large'}
                                            onClick={cmdExportToExcel}
                                        >
                                            Export ke Excel
                                        </Button>
                                    </Space>
                                </Col>
                                <Col span={24}>
                                    <Divider/>
                                </Col>
                                {(user.role === 'SUPERADMIN') &&
                                <Col span={12}>
                                    <Space size={"large"}>
                                        <h4>Wilayah : </h4>
                                        <Select
                                            style={{width: '200px'}}
                                            loading={loading}
                                            name={'region_name'}
                                            value={selectedRegion ? selectedRegion.region_name : ''}
                                            onChange={(value) => handleRegion(value)}
                                        >
                                            {region.map((element) => (
                                                <Option key={element.id} value={element.id}>{element.region_name}</Option>
                                            ))}
                                        </Select>
                                    </Space>
                                </Col>}
                            </Row>

                            <Table columns={column} dataSource={dataTable} scroll={{x: true}} loading={loading}/>
                        </Space>
                    </div>
                </Content>
            </Layout>
        </>
    )
}

export default TransactionPage;