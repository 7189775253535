import axiosIntance from "./axios-instance";
import Constant from '../constant'
import moment from "moment";

const getTransactionAll = async (query) => {
    console.log('1', query)
    try {
        let response = await axiosIntance().get("transaction/all" + `?region_id=${query.region_id}`, {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            }
        })
        return {status: true, transaction: response.data.transaction}
    } catch (error) {
        return {
            status: false,
            transaction: null,
            message: error.response.message
        }
    }
}

const getTransactionExport = async (request) => {
    console.log('date ex', request.reportDate)
    console.log('region_id', request.region_id)
    try{
        let response = await axiosIntance().get('transaction/export/' + `report-transaction_${moment(request.reportDate).format('DD-MM-YYYY')}` + `?region_id=${request.region_id}`, {
            headers: {
                token: localStorage.getItem(Constant.STORAGE_KEY.TOKEN)
            },
            responseType: 'blob'
        })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report-transaction_${moment(request.reportDate).format('DD-MM-YYYY')}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        return {status: true, transaction: null}
    } catch (error) {
        return {
            status: false,
            transaction: null,
            message: error.response.message
        }
    }
}

export {
    getTransactionAll,
    getTransactionExport
}